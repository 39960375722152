import { ProduitModel } from '@innedit/innedit-react';
import { CommandeProduitType } from '@innedit/innedit-type';
import { Link } from 'gatsby';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconChevron from '../../icons/Chevron';
import IconClose from '../../icons/Close';
import Button from '../Button';

const CartListItem: FC<{
  handleDecrementCartItem: (itemId: string) => void;
  handleIncrementCartItem: (itemId: string) => void;
  handleRemoveItem: (itemId: string) => void;
  index: number;
  item: CommandeProduitType;
}> = function ({
  index,
  item,
  handleDecrementCartItem,
  handleIncrementCartItem,
  handleRemoveItem,
}) {
  const {
    i18n: { language },
  } = useTranslation();
  const [produit, setProduit] = useState<any>();

  useEffect(() => {
    let isMounted = true;

    ProduitModel.get(item.id)
      .then(documentSnapshot => {
        if (isMounted) {
          setProduit(documentSnapshot);
        }

        return isMounted;
      })
      .catch(console.error);

    return () => {
      isMounted = false;
    };
  }, [item]);

  if (!item) {
    return null;
  }

  const { price } = item;

  const handleOnClick = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    const t = e.currentTarget.getAttribute('data-type');
    switch (t) {
      case 'sub': {
        handleDecrementCartItem(item.id);
        break;
      }

      case 'add': {
        handleIncrementCartItem(item.id);
        break;
      }

      case 'remove': {
        handleRemoveItem(item.id);
        break;
      }

      default:
    }
  };

  const image =
    produit &&
    produit.medias &&
    produit.medias.length > 0 &&
    `${produit.medias[0].origin}/w_180,h_180/${produit.medias[0].pathname}`;

  let to = `/${language}/`;
  if (produit) {
    if ('fr' === language) {
      to += `produit/${produit.pathname}/`;
    } else {
      to += `product/${produit.pathname}/`;
    }
  }

  return (
    <tr className="border-t border-b">
      <td className="itemInfos py-4">
        {image && (
          <img
            alt={produit.name}
            className="float-left w-20 block mr-3"
            src={image}
          />
        )}
        <Link className="block" data-index={index} to={to}>
          {produit?.name}
        </Link>

        <span className="italic text-xs">{produit?.reference}</span>
      </td>
      <td className="">
        <div className="grid grid-cols-3">
          <div className="flex justify-end items-center">
            {undefined !== produit?.qtyMin &&
              produit.qtyMin < item.quantity &&
              produit.qtyMin > 0 && (
                <Button
                  className="rounded-full focus:outline-none outline-none p-0 m-0 transform rotate-180"
                  data-type="sub"
                  iconLeft={IconChevron}
                  iconSize={14}
                  onClick={handleOnClick}
                  status="link"
                />
              )}
          </div>

          <span className="flex justify-center items-center">
            {item.quantity}
          </span>
          <div className="flex justify-start items-center">
            {(undefined === produit?.qtyMax ||
              produit.qtyMax > item.quantity) && (
              <Button
                className="rounded-full focus:outline-none outline-none p-0 m-0"
                data-type="add"
                iconLeft={IconChevron}
                iconSize={14}
                onClick={handleOnClick}
                status="link"
              />
            )}
          </div>
        </div>
      </td>
      <td className="">
        <div className="flex justify-center items-center">
          <span className="price">{price} €</span>
        </div>
      </td>
      <td>
        <div className="flex justify-center items-center">
          <Button
            data-type="remove"
            iconLeft={IconClose}
            onClick={handleOnClick}
            status="link"
          />
        </div>
      </td>
    </tr>
  );
};

export default CartListItem;
