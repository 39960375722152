import { CommandeProduitType } from '@innedit/innedit-type';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Item from './Item';

const CartList: FC<{
  handleDecrementCartItem: (itemId: string, variantId?: string) => void;
  handleIncrementCartItem: (itemId: string, variantId?: string) => void;
  handleRemoveItem: (itemId: string, variantId?: string) => void;
  items: CommandeProduitType[];
}> = function ({
  items,
  handleDecrementCartItem,
  handleIncrementCartItem,
  handleRemoveItem,
}) {
  const { t } = useTranslation();

  return (
    <table className="table-auto">
      <thead>
        <tr>
          <th className="">{t('components.commande.produits.produit')}</th>
          <th className="">{t('components.commande.produits.quantite')}</th>
          <th className="">{t('components.commande.produits.prix')}</th>
          <th />
        </tr>
      </thead>

      <tbody>
        {items.map((ligne, index) => (
          <Item
            key={ligne.id}
            handleDecrementCartItem={handleDecrementCartItem}
            handleIncrementCartItem={handleIncrementCartItem}
            handleRemoveItem={handleRemoveItem}
            index={index}
            item={ligne}
          />
        ))}
      </tbody>
    </table>
  );
};

export default CartList;
